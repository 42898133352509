<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">课程合作管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">结算列表</a>
        </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start; ">
                    <div class="searchbox"
                         style="margin-bottom:5px;width: 100%;align-items: flex-start;justify-content: flex-start;">
                        <div title="合作方名称" class="searchboxItem ci-full">
                            <span class="itemLabel">合作方名称:</span>
                            <el-input
                                    v-model="partnerName"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入合作方名称"
                            />
                        </div>
                        <div title="姓名" class="searchboxItem ci-full">
                            <span class="itemLabel">姓名:</span>
                            <el-input
                                    v-model="videoUserVisituser"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入姓名"
                            />
                        </div>
                        <div title="课程名称" class="searchboxItem ci-full">
                            <span class="itemLabel">课程名称:</span>
                            <el-input
                                    v-model="courseName"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入课程名称"
                            />
                        </div>
                        <div title="课件名称" class="searchboxItem ci-full">
                            <span class="itemLabel">课件名称:</span>
                            <el-input
                                    v-model="outlineName"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入课件名称"
                            />
                        </div>
                        <div class="btnBox" style="margin-left:20px">
                            <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
                            <el-button class="bgc-bv" round @click="handleCreate()">导出</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                                ref="multipleTable"
                                :data="tableData"
                                :height="tableHeight"
                                size="small"
                                tooltip-effect="dark"
                                style="width: 100%"
                                :header-cell-style="tableHeader"
                                stripe
                        >
                            <el-table-column
                                    label="序号"
                                    align="center"
                                    type="index"
                                    width="100px"
                                    :index="indexMethod"
                            />
                            <el-table-column
                                    label="合作方名称"
                                    align="left"
                                    prop="partnerName"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <el-table-column
                                    label="姓名"
                                    align="left"
                                    prop="videoUserVisituser"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <el-table-column
                                    label="课程名称"
                                    align="left"
                                    prop="courseName"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <el-table-column
                                    label="课件名称"
                                    align="left"
                                    prop="outlineName"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <el-table-column
                                    label="观看次数"
                                    align="left"
                                    prop="viewNum"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <el-table-column
                                    label="第一次观看时间"
                                    align="left"
                                    prop="createTime"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <el-table-column
                                    label="最后一次观看时间"
                                    align="left"
                                    prop="updateTime"
                                    show-overflow-tooltip
                                    min-width="100"
                            />
                            <Empty slot="empty"/>
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
            </div>
        </div>
    </div>
</template>
<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import {resetKeepAlive} from "@/utils/common";

    export default {
        name: "Partner/accountList",
        components: {
            Empty,
            PageNum
        },
        mixins: [List],
        data() {
            return {
                courseName: '',
                createTime: '',
                outlineName: '',
                partnerBillId: '',
                partnerId: '',
                partnerName: '',
                updateTime: '',
                videoUserVisituser: '',
                viewNum: ''
            };
        },
        computed: {},
        created() {
            this.getTableHeight();
        },
        methods: {
            getData(pageNum = 1) {
                const params = {
                    pageNum: pageNum,
                    pageSize: this.pageSize,
                    partnerName: this.partnerName ? this.partnerName : "",
                    videoUserVisituser: this.videoUserVisituser ? this.videoUserVisituser : "",
                    courseName: this.courseName ? this.courseName : "",
                    outlineName: this.outlineName ? this.outlineName : ""
                };
                this.doFetch({
                    url: "/biz/chinahrt/partner/bill/page",
                    params,
                    pageNum
                });
            },
            // 导出
            handleCreate() {
                const params = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                };
                if (this.partnerId) {
                    params.partnerId = this.partnerId;
                }
                if (this.partnerName) {
                    params.partnerName = this.partnerName;
                }
                if (this.videoUserVisituser) {
                    params.videoUserVisituser = this.videoUserVisituser;
                }
                if (this.courseName) {
                    params.courseName = this.courseName;
                }
                if (this.outlineName) {
                    params.outlineName = this.outlineName;
                }

                this.$post("/biz/chinahrt/partner/bill/export", params).then(
                    res => {
                        if (res.status == "0") {
                            //window.location.href = res.data;
                            window.open(res.data);
                        }
                    }
                );
            },
            //
            getTableHeight(opDom = true, page = true) {
                let tHeight =
                    window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1) * 16;
                if (opDom) {
                    tHeight -= 40 + 0.675 * 16 + 1;
                }
                if (page) {
                    tHeight -= 32;
                }
                this.tableHeight = tHeight;
            }
        },
        beforeRouteLeave: resetKeepAlive,
        watch: {}
    };
</script>
<style lang="less">
    .addlist {
        .el-icon-plus:before {
            content: "\e6d9";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .table-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        .el-switch__core {
            width: 40px !important;
        }
        .el-switch__core:after {
            background-color: #fff;
        }
        span {
            line-height: 20px;
            margin-left: 5px;
        }
    }

    .operationControl > div > div > span {
        min-width: 6rem;
    }
</style>
<style lang="less" scoped>
</style>
